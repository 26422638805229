var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "speaktrump",
    class: { active: _vm.trumpActive },
    style: _vm.speakStyle(_vm.trumpActive),
    on: {
      mousedown: function ($event) {
        return _vm.trumpMousedown()
      },
      mouseup: function ($event) {
        return _vm.trumpMouseup()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }