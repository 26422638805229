<template>
    <div class="speaktrump" :class="{active: trumpActive}" :style="speakStyle(trumpActive)" @mousedown="trumpMousedown();" @mouseup="trumpMouseup();"></div>
</template>

<script>
import Recorder from 'js-audio-recorder';
let recorder = new Recorder();
let duration = 0;
recorder.onprogress = function(params){
    console.log(params)
    duration = params.duration;
}
export default {
    data(){
        return{
            trumpActive: false,
        }
    },
    methods:{
        speakStyle(trumpActive){
            // console.log(trumpActive)
            let style = {};
            let backImg = null;
            if(!trumpActive){
                backImg = require('@/assets/img/08-Device-check/audio_4.png');
            }else{
                backImg = require('@/assets/img/08-Device-check/gif-mic-new.gif');
            }
            style.backgroundImage = `url(${backImg})`;

            return style;
        },
        // speak喇叭按下鼠标
        trumpMousedown(){
            this.trumpActive = true;
            recorder = new Recorder({
                sampleBits: 16,         // 采样位数，支持 8 或 16，默认是16
                sampleRate: 16000,      // 采样率，支持 11025、16000、22050、24000、44100、48000
                numChannels: 1,         // 声道，支持 1 或 2， 默认是1
            });
            recorder.start();
        },
        // speak喇叭抬起鼠标
        trumpMouseup(){
            recorder.stop();
            // console.log(recorder)
            // console.log(recorder.getPlayTime())
            // recorder.play();
            // return
            let blob = recorder.getPCMBlob();
            // let blob = recorder.getWAVBlob();
            // recorder.destroy();
            // recorder = null;
            // let data = {
            //     recorder: recorder,
            //     duration: duration
            // }
            if(this.trumpActive){
                this.trumpActive = false;
                this.$emit('trumpMouseup', recorder)
            }
        },
    }
}
</script>

<style scoped lang="scss">
.speaktrump{
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    &.active{
        background-size: 95% 95%;
    }
}
</style>